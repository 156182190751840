import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const ClickableText = ({ children, onClick, ...rest }) => (
  <Typography
    component="span"
    tabIndex={0}
    onClick={onClick}
    role="button"
    onKeyDown={event => {
      if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
        onClick(event);
      }
    }}
    {...rest}
  >
    {children}
  </Typography>
);

ClickableText.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

ClickableText.defaultProps = {};

export default ClickableText;
