/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import WhitePlayButton from '~images/buttons/play_btn_white.svg';

import {
  formatDurationOfFirstVideoTishaBav,
  getPlaylistTypeIcon,
  getPlaylistType
} from '~utils/playlist-helpers';
import Link from '~components/Link';

import WhiteCheckbox from '~images/other/white-checkbox.svg';
import { useTestId } from '~utils/set-testid';
import { CardActionArea } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100% !important',
    backgroundColor: 'transparent',
    position: 'relative',
    borderRadius: 15,
    boxShadow: '4px 4px 0px 0px rgba(0, 8, 17, 0.05)'
  },
  media: {
    aspectRatio: '16 / 9',
    minHeight: '100px'
  },
  description: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    height: '145px',
    padding: '13px'
  },
  title: {
    display: '-webkit-box',
    margin: 'auto',
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Inter',
    textAlign: 'center',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  },
  subtitle: {
    fontFamily: 'Inter',
    margin: 'auto',
    fontSize: 12,
    textAlign: 'center',
    marginTop: '8px'
  },
  duration: {
    fontSize: 14,
    marginBottom: 0,
    marginLeft: 3,
    color: '#FFFFFF'
  },
  watchedArea: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    right: 11,
    bottom: 7
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 20,
    width: 50,
    height: 50
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginBottom: '20px'
  },
  resourceInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gridGap: '8px',
    justifyContent: 'center',
    marginTop: '16px'
  },
  typePartSubtitle: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: 'rgba(0,0,0, 0.54)',
    marginBottom: 0
  },
  author: {
    fontFamily: 'Inter',
    margin: 'auto',
    fontSize: 12,
    textAlign: 'center',
    marginTop: '4px'
  }
}));

const getSubtitle = (videos, subtitleType = 'episodes', resourceType = null) => {
  const duration =
    videos && videos.length > 0 && formatDurationOfFirstVideoTishaBav(videos[0].length_in_seconds);
  const hasMultipleVideos = videos && videos.length > 1;

  if (subtitleType === 'parts') {
    return `Part 1 of ${videos?.length} • ${duration}`;
  }
  if (subtitleType === 'type-part-duration') {
    const part = videos?.length > 1 ? `Part 1 of ${videos?.length}` : '';
    return [resourceType, part, duration].filter(Boolean).join(' • ');
  }
  return hasMultipleVideos ? `${videos.length} Episodes • ${duration}` : `1 Episode • ${duration}`;
};

const PlaylistCardFoundation = memo(
  ({
    title,
    cover_image,
    cover_color,
    custom_subtitle,
    videos,
    progress,
    subtitleType,
    author,
    isAudio
  }) => {
    const styleProps = useMemo(() => ({ mediaCardBackgroundColor: cover_color }), [cover_color]);
    const classes = useStyles(styleProps);

    const resourceType = getPlaylistType(videos?.length, isAudio);
    const subtitle = custom_subtitle || getSubtitle(videos, subtitleType, resourceType);
    const typeIcon = getPlaylistTypeIcon(resourceType);

    const isWatched = progress.progress > 95;

    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={cover_image?.url} title={title} />
          {isWatched && (
            <span className={classes.watchedArea}>
              <img src={WhiteCheckbox} width={32} height={32} alt="yes" />
              <Typography
                gutterBottom
                variant="subtitle2"
                component="p"
                className={classes.duration}
              >
                Completed
              </Typography>
            </span>
          )}
          <img className={classes.playBtn} src={WhitePlayButton} alt="play button" />
          {!!progress.progress && (
            <LinearProgress
              variant="determinate"
              value={progress.progress}
              className={classes.progressLine}
            />
          )}
        </CardActionArea>
        <CardContent className={classes.description}>
          <Typography gutterBottom variant="subtitle2" component="p" className={classes.title}>
            {title}
          </Typography>
          {subtitleType === 'type-part-duration' && (
            <div className={classes.cardFooter}>
              <div>
                {subtitle && (
                  <span className={classes.resourceInfo}>
                    <img src={typeIcon} alt="icon" />
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      component="p"
                      className={classes.typePartSubtitle}
                    >
                      {subtitle}
                    </Typography>
                  </span>
                )}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.author}
                >
                  {author}
                </Typography>
              </div>
            </div>
          )}
          {subtitleType !== 'type-part-duration' && (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
);

PlaylistCardFoundation.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  custom_subtitle: PropTypes.string.isRequired,
  cover_image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  cover_color: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      length_in_seconds: PropTypes.number.isRequired
    })
  ).isRequired,
  progress: PropTypes.shape({
    last_video_sequence_number: PropTypes.number,
    last_video_position: PropTypes.number,
    last_video_id: PropTypes.string,
    progress: PropTypes.number
  }).isRequired,
  subtitleType: PropTypes.oneOfType(['episodes', 'parts', 'type-part-duration']),
  isAudio: PropTypes.bool
};
PlaylistCardFoundation.defaultProps = {
  author: '',
  subtitleType: 'episodes',
  isAudio: false
};

// Note: This is a higher order component that will return the card with a link with it if visible
// This is done to because if the card is not visible it has aria-visible='hidden', but also has
// an a tag down the the tree which causes low score of Accessability on lighthouse.

const ModernPlaylistCard = memo(
  ({
    url,
    progress,
    title,
    cover_image,
    cover_color,
    is_audio,
    custom_subtitle,
    videos,
    subtitleType,
    author
  }) => {
    const lastVideoInfo =
      progress && progress.last_video_id
        ? { index: progress.last_video_sequence_number, seekToSecond: progress.last_video_position }
        : {};

    const testid = useTestId('playlist-item');

    return (
      <Link to={url} style={{ textDecoration: 'none' }} state={lastVideoInfo} {...testid}>
        <PlaylistCardFoundation
          progress={progress}
          title={title}
          cover_image={cover_image}
          cover_color={cover_color}
          custom_subtitle={custom_subtitle}
          videos={videos}
          subtitleType={subtitleType}
          isAudio={is_audio}
          author={author}
        />
      </Link>
    );
  }
);

ModernPlaylistCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  custom_subtitle: PropTypes.string,
  cover_image: PropTypes.shape({
    fluid: PropTypes.string.isRequired
  }).isRequired,
  cover_color: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      length_in_seconds: PropTypes.number.isRequired
    })
  ).isRequired,
  progress: PropTypes.shape({
    last_video_sequence_number: PropTypes.number,
    last_video_position: PropTypes.number,
    last_video_id: PropTypes.string,
    progress: PropTypes.number
  }),
  is_audio: PropTypes.bool,
  subtitleType: PropTypes.oneOfType(['episodes', 'parts', 'type-part-duration'])
};

ModernPlaylistCard.defaultProps = {
  progress: { progress: 0 },
  cover_color: 'transparent',
  custom_subtitle: '',
  is_audio: false,
  subtitleType: 'episodes',
  author: ''
};

export default ModernPlaylistCard;
