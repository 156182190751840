import Cookies from 'js-cookie';

const COOKIE_KEY = 'previousPath';
const LAST_NON_REDIRECT_PATH_COOKIE_KEY = 'lastNonRedirectPath';
const REDIRECT_PATHS = [
  '/checkout/login',
  '/checkout/register',
  '/login',
  '/register',
  '/subscribe',
  '/checkout',
  '/onboarding',
  '/search',
  '/unemployed',
  '/premium-subscription',
  '/apple-sign-in',
  '/express-checkout',
  '/kickstarter-pledge'
];

export const isPreviousPathOfInterest = (location, prevLocation) => {
  if (!prevLocation) {
    return false;
  }

  const currentPath = location.pathname;
  const isRedirectPath = REDIRECT_PATHS.some(redirect => currentPath.includes(redirect));

  const prevPath = prevLocation.pathname;
  const isPrevPathRedirect = REDIRECT_PATHS.some(redirect => prevPath.includes(redirect));

  const isPathOfInterest = isRedirectPath && !isPrevPathRedirect;
  return isPathOfInterest;
};

export const setPreviousLocationData = ({ pathname, state }) => {
  Cookies.set(COOKIE_KEY, { pathname, state });
};

export const getPreviousLocationData = () => {
  const result = { pathname: '/', state: undefined };

  const rawCookieValue = Cookies.get(COOKIE_KEY);
  if (!rawCookieValue) {
    return result;
  }

  try {
    const previousLocationData = JSON.parse(rawCookieValue);
    return previousLocationData; // don't want to overcomplicate things with defensive programming; assume stored data is in expected shape
  } catch (error) {
    console.error('Unparsable previous location data, will be ignored:', rawCookieValue, error); // eslint-disable-line
    return result;
  }
};

/**
 * Set the last non-redirect path cookie for the conversion framework
 * This cookie is shared between marketing site and members site
 * Non-redirect paths are the all paths except the path we should not redirect a user once login, register, checkout.
 * @param {string} pathname - relative path starting with /
 * @param {*} state - location state
 */
export const persistMarketingSiteNonRedirectPath = (pathname, state) => {
  const isRedirectPath = REDIRECT_PATHS.some(redirect => pathname.includes(redirect));
  if (!isRedirectPath) {
    Cookies.set(
      LAST_NON_REDIRECT_PATH_COOKIE_KEY,
      { pathname, state },
      { domain: process.env.GATSBY_AUTH_DOMAIN }
    );
  }
};
