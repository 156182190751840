import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import isBot from 'isbot';
import loadable from '@loadable/component';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import unfurl from '~prismic/unfurl/navigation';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import clsx from '~styles/clsx';
import useConversionFramework from '~hooks/useConversionFramework';
import { getPagePathInMembers } from '~utils/common-site-helper';
import { isUserAuthTokenAvailable } from '~utils/request';
import RedirectWithNotice from './RedirectWithNotice';

const SEO = loadable(() => import('./SearchEngineOptimization'));
const ExitIntentPopup = loadable(() => import('~components/popups/ExitIntentPopup'));
const NewsletterSignupPopup = loadable(() => import('~components/popups/NewsletterSignupPopup'));
const Banners = loadable(() => import('./Banners'));
const MarketingSecondaryHeader = loadable(() => import('./MarketingSecondaryHeader'));
const GuestNavbar = loadable(() => import('~layout/GuestNavbar'));
const NewFooter = loadable(() => import('~layout/NewFooter'));
const BlackFridayBanner = loadable(() => import('~components/black-friday/BlackFridayBanner'));

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);
const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

const useStyles = makeStyles({
  mainBody: {
    backgroundColor: props => props.backgroundColor || 'inherit'
  },
  calcMinHeight: {
    minHeight: '100vh',
    overflow: props => (props.allowStickyContent ? 'visible' : 'hidden')
  },
  headerStyle: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    zIndex: 1100
  },
  hideContent: {
    opacity: 0.01
  }
});

const defaultSeoProps = {
  seo_title: 'The Torah Brought To Life',
  seo_description:
    'Fall in love with Torah. Aleph Beta brings the Torah to life with colorful videos and study guides, uncovering profound insights about how we should live our lives.',
  seo_keywords: 'jewish holy book',
  seo_image: '/static/images/site_social_image_raw.png'
};

const Page = memo(
  ({
    children,
    backgroundColor,
    hideBanner,
    hideNavbar,
    hideFooter,
    hideExitIntent,
    hideNewsletterSignup,
    hideMarketingSecondaryHeader,
    seo,
    showContentAfterPageLoad,
    redirectLoggedInUsersToMembers,
    redirectToMembers,
    membersPath,
    allowStickyContent
  }) => {
    const classes = useStyles({ backgroundColor, allowStickyContent });
    const { isMarketingSite } = useConversionFramework();
    const { jwtAvailable, isUserStateLoading, marketingUser } = useUserContext().session;

    const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};

    const query = graphql`
      {
        allPrismicWipNavigation {
          edges {
            node {
              data {
                body {
                  ... on PrismicWipNavigationDataBodyTest {
                    primary {
                      menu_title
                      menu_link
                    }
                    items {
                      menu_item_document {
                        link_type
                        url
                        type
                        uid
                        isBroken
                        __typename
                        document {
                          ... on PrismicPodcastPlaylist {
                            data {
                              podcast_title {
                                text
                              }
                            }
                          }
                          ... on PrismicBook {
                            data {
                              title
                              chapters {
                                chapter {
                                  uid
                                  url
                                  document {
                                    ... on PrismicParshat {
                                      data {
                                        title
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicHolidayRedesign {
                            data {
                              title
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        allPrismicWipNavigation {
          nodes {
            data {
              company_links {
                link_title
                link_url
              }
            }
          }
        }
        prismicWipNavigation {
          data {
            disable_guest_nav_bar
            show_about
            header_navigation {
              link_url
              link_title
              highlight
            }
            guest_header_navigation {
              link_title
              link_url
              highlight
            }
            guest_footer_navigation {
              link_title
              link_url
              icon {
                url
                alt
              }
            }
          }
        }
      }
    `;

    const prismicData = useStaticQuery(query);
    const { guestHeaderLinks, footerSocialLinks, showAbout } = unfurl(prismicData);

    /**
     * Hiding the FOUC from user until the javascript starts to work on the DOM
     * This special prop `showContentAfterPageLoad` can be passed when there are conditions to show up the page
     */
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const hideContent = showContentAfterPageLoad && !isPageLoaded;
    useEffect(() => {
      if (!showContentAfterPageLoad) {
        return;
      }
      // During gatsby build time, window API is not available
      if (typeof window === 'undefined') {
        setIsPageLoaded(false);
      } else {
        setIsPageLoaded(true);
      }
    }, [showContentAfterPageLoad]);

    const [redirection, setRedirection] = useState(null);

    useEffect(() => {
      if (
        typeof window === 'undefined' ||
        isUserStateLoading ||
        !isMarketingSite ||
        !(redirectLoggedInUsersToMembers || redirectToMembers)
      ) {
        return;
      }

      //  TODO: Remove when we fully migrate to Free Trial
      // in split site, we redirect any logged in user to members site
      const hasNonFreeTrialRedirect = !IS_FREE_TRIAL_ENABLED && isUserAuthTokenAvailable();

      // We redirect subscribers to the members site
      const shouldRedirect = IS_FREE_TRIAL_ENABLED && marketingUser?.isSubscriber;

      if (redirectToMembers || hasNonFreeTrialRedirect || shouldRedirect) {
        const url = getPagePathInMembers(
          `${membersPath || window.location.pathname}${window.location.search}`
        );
        setRedirection({ url, description: 'We are redirecting you to the members page.' });
      }
    }, [
      isMarketingSite,
      isUserStateLoading,
      redirectLoggedInUsersToMembers,
      redirectToMembers,
      membersPath,
      marketingUser?.isSubscriber
    ]);

    return (
      <>
        <SEO {...seo} />

        {!hideExitIntent && !isBot(userAgent) && <ExitIntentPopup />}
        {!hideNewsletterSignup && !isBot(userAgent) && <NewsletterSignupPopup />}
        {redirection?.url && (
          <RedirectWithNotice
            url={redirection.url}
            description={redirection.description}
            seconds={3}
          />
        )}

        <header className={clsx(classes.headerStyle, hideContent && classes.hideContent)}>
          {!hideBanner && !isBot(userAgent) && <Banners />}
          {!hideNavbar && <GuestNavbar items={guestHeaderLinks} showAbout={showAbout} />}
          {/* Show secondary marketing header */}
          {isMarketingSite && !hideMarketingSecondaryHeader && <MarketingSecondaryHeader />}
          {IS_BLACK_FRIDAY_OFFER_ENABLED && <BlackFridayBanner />}
        </header>

        <main
          className={clsx(
            classes.calcMinHeight,
            classes.mainBody,
            hideContent && classes.hideContent
          )}
        >
          {children}
        </main>

        {!hideFooter && (
          <footer className={clsx(hideContent && classes.hideContent)}>
            <NewFooter connectLinks={footerSocialLinks} isLoggedIn={jwtAvailable} />
          </footer>
        )}
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  hideBanner: PropTypes.bool,
  hideNavbar: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideExitIntent: PropTypes.bool,
  hideNewsletterSignup: PropTypes.bool,
  hideMarketingSecondaryHeader: PropTypes.bool,
  seo: PropTypes.shape({
    seo_title: PropTypes.string,
    seo_description: PropTypes.string,
    seo_keywords: PropTypes.string,
    seo_image: PropTypes.string
  }),
  showContentAfterPageLoad: PropTypes.bool,
  redirectLoggedInUsersToMembers: PropTypes.bool,
  redirectToMembers: PropTypes.bool,
  membersPath: PropTypes.string,
  allowStickyContent: PropTypes.bool,
  backgroundColor: PropTypes.string
};

Page.defaultProps = {
  hideBanner: false,
  hideNavbar: false,
  hideFooter: false,
  hideExitIntent: false,
  hideNewsletterSignup: false,
  hideMarketingSecondaryHeader: false,
  seo: defaultSeoProps,
  showContentAfterPageLoad: false,
  redirectLoggedInUsersToMembers: false,
  redirectToMembers: false,
  allowStickyContent: false,
  membersPath: '',
  backgroundColor: ''
};

export default Page;
